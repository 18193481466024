import styled, { css } from 'styled-components';
import { devices, renderStyledElementStyles } from '../../../../helpers';

const oneEventCountCss = css`
  .slick-track {
    width: 100% !important;
  }

  .slick-slide {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements.container?.elements?.oneEvent?.slickSlide,
      )}
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements.container?.elements?.oneEvent?.slickSlide,
      )}
  }
`;

const oneTwoEventCountCss = css`
  .slick-slide {
    &:first-child {
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.vStyles.elements.container?.elements?.oneTwoEvent
            ?.slickSlide.firstChild,
        )}
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.dStyles.elements.container?.elements?.oneTwoEvent
            ?.slickSlide.firstChild,
        )}
    }

    &:last-child {
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.vStyles.elements.container?.elements?.oneTwoEvent
            ?.slickSlide?.lastChild,
        )}
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.dStyles.elements.container?.elements?.oneTwoEvent
            ?.slickSlide?.lastChild,
        )}
    }
  }
`;

export const CarouselSecondWrapper = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements.container)}
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements.container)}
  
  ${({ isOneEvent }) => isOneEvent && oneEventCountCss};

  ${({ isOneTwoEvent }) => isOneTwoEvent && oneTwoEventCountCss};

  .slick-arrow {
    width: 100px;
    height: 100px;
    position: absolute;
    z-index: 10;
    color: white;
    text-align: center;
    top: 35.5%;
    box-shadow: none;

    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements.container?.elements?.slickArrow,
      )}
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements.container?.elements?.slickArrow,
      )};

    @media only screen and (max-width: ${devices.breakpoints.tablet}) {
      top: 29%;
    }

    &:hover {
      box-shadow: none;
    }

    &.slick-disabled {
      display: none !important;
    }

    &.slick-prev:before,
    &.slick-next:before {
      color: #fff;
    }

    &.slick-prev {
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.vStyles.elements.container?.elements?.slickPrev,
        )}
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.dStyles.elements.container?.elements?.slickPrev,
        )}
    }

    &.slick-next {
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.vStyles.elements.container?.elements?.slickNext,
        )}
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.dStyles.elements.container?.elements?.slickNext,
        )}
    }
  }
`;
