import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Carousel from '../../../../../shared/Carousel/Carousel';
import { CarouselSecondItem } from '../../innerComponents';
import { CarouselSecondWrapper } from '../../styles';

const carouselSecondSettings = {
  slidesToShow: 3,
  infinite: false,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

export const CarouselSecondDisplayContent = (props) => {
  const { events = [] } = props;

  const { tenantId } = useSelector(({ pageConfig }) => pageConfig);

  const isOneEvent = events.length === 1;
  const isOneTwoEvent = events.length === 2 || isOneEvent;

  return (
    <CarouselSecondWrapper
      isOneEvent={isOneEvent}
      isOneTwoEvent={isOneTwoEvent}
      data-testid="related-events-second-carousel"
    >
      <Carousel embedSettings={carouselSecondSettings}>
        {events.map((event, index) => (
          <CarouselSecondItem
            key={index}
            tenantId={tenantId}
            event={event}
            isEven={index % 2 === 0}
          />
        ))}
      </Carousel>
    </CarouselSecondWrapper>
  );
};

CarouselSecondDisplayContent.propTypes = {
  events: PropTypes.array,
  tenantId: PropTypes.string,
};
