import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const CarouselSecondBottom = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 125px;
  align-items: ${({ theme }) =>
    theme.globalVariables.isRTL ? 'flex-end' : 'flex-start'};
  text-align: ${({ theme }) =>
    theme.globalVariables.isRTL ? 'right' : 'left'};

  ${({ theme, isEven }) =>
    renderStyledElementStyles(
      theme,
      isEven
        ? theme.module.vStyles.elements.carouselBottom?.even
        : theme.module.vStyles.elements.carouselBottom?.odd,
    )}

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements.carouselBottom,
    )}
`;
