import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const CarouselSecondEventButton = styled.h5`
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements.carouselEventButton,
    )}
  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements.carouselEventButton,
    )}
`;
