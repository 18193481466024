import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const Container = styled.div`
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements.container)}
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements.container)}
  
  .slick-cloned {
    ${({ hasLessCount }) => hasLessCount && 'display: none;'}
  }

  .slick-arrow {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements.container?.elements?.slickArrow,
      )}
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements.container?.elements?.slickArrow,
      )}
  }

  .slick-prev {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements.container?.elements?.slickPrev,
      )}
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements.container?.elements?.slickPrev,
      )}
  }

  .slick-next {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements.container?.elements?.slickNext,
      )}
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements.container?.elements?.slickNext,
      )}
  }
`;
