import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components';
import Carousel from '../../../../../shared/Carousel/Carousel';
import { RelatedEventsBlock } from '../../innerComponents';
import { Container } from '../../styles';

const CarouselBlockContainer = styled.div`
  .row {
    margin: 0;
  }
`;

const carouselFistSettings = {
  slidesToShow: 3,
  dots: false,
  infinite: false,
  responsive: [
    {
      breakpoint: 812,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

export const CarouselFirstDisplayContent = (props) => {
  const { events = [] } = props;

  const { tenantId } = useSelector(({ pageConfig }) => pageConfig);

  const themeConfig = useContext(ThemeContext);
  const dStylesElements = themeConfig.module.dStyles.elements;

  const {
    contentGrid: { xsOffset, xs },
  } = dStylesElements.container;

  const {
    contentGrid: { xs: blockXs },
  } = dStylesElements.block;

  return (
    <Container
      hasLessCount={events.length < 3}
      data-testid="related-events-first-carousel"
    >
      <div className="container">
        <div className="row">
          <div className={`col-xs-${xs} col-xs-offset-${xsOffset}`}>
            <Carousel embedSettings={carouselFistSettings}>
              {events.map((event, index) => (
                <CarouselBlockContainer key={index}>
                  <div className="row">
                    <div className={`col-xs-${blockXs}`}>
                      <RelatedEventsBlock event={event} tenantId={tenantId} />
                    </div>
                  </div>
                </CarouselBlockContainer>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </Container>
  );
};

CarouselFirstDisplayContent.propTypes = {
  events: PropTypes.array,
};
