import styled from 'styled-components';

export const CarouselSecondInfo = styled.div`
  z-index: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: ${({ theme }) =>
    theme.globalVariables.isRTL ? 'flex-end' : 'flex-start'};
  text-align: ${({ theme }) =>
    theme.globalVariables.isRTL ? 'right' : 'left'};
`;
