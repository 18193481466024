import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const CarouselSecondContainer = styled.div`
  height: 100%;
  border-top: 4px solid;
  border-bottom: 4px solid;

  ${({ theme, isEven }) =>
    renderStyledElementStyles(
      theme,
      isEven
        ? theme.module.vStyles.elements.carouselContainer?.even
        : theme.module.vStyles.elements.carouselContainer?.odd,
    )}
`;
