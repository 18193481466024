import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const ImageWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  color: #fff;
  z-index: 1;
  text-align: center;
  width: 100%;

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements.imageWrapper,
    )}
`;
