import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ThemeContext } from 'styled-components';
import {
  CarouselSecondBottom,
  CarouselSecondEventButton,
  CarouselSecondEventDescription,
} from '../../styles';
import { HTMLtoJSX } from '../../../../../../helpers';
import { CallToAction } from '../../../sharedComponents';

export const CarouselSecondItemBottom = ({ isEven, valueProposition }) => {
  const { t } = useTranslation();
  const { name: themeName } = useContext(ThemeContext);

  return (
    <CarouselSecondBottom
      isEven={isEven}
      data-testid="related-events-carousel-second-item-bottom"
    >
      {valueProposition && (
        <CarouselSecondEventDescription className="formatted-text">
          {HTMLtoJSX(valueProposition)}
        </CarouselSecondEventDescription>
      )}
      {themeName === 'Default' ? (
        <CallToAction look="hollow" size="medium">
          {t('event.related-events.visit-site')}
        </CallToAction>
      ) : (
        <CarouselSecondEventButton>
          {t('event.related-events.visit-site')}
        </CarouselSecondEventButton>
      )}
    </CarouselSecondBottom>
  );
};

CarouselSecondItemBottom.propTypes = {
  isEven: PropTypes.bool,
  valueProposition: PropTypes.string,
};
