import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const CarouselSecondEventDescription = styled.h5`
  margin-bottom: 20px;

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements.carouselEventDescription,
    )}
`;
