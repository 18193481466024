import React from 'react';
import PropTypes from 'prop-types';
import {
  CarouselSecondEventDate,
  CarouselSecondEventName,
  CarouselSecondGradient,
  CarouselSecondInfo,
  CarouselSecondTop,
} from '../../styles';

export const CarouselSecondItemTop = ({
  backgroundImage,
  name,
  formattedDate,
}) => (
  <CarouselSecondTop
    className="lazy"
    data-background-image={backgroundImage}
    data-testid="related-events-carousel-second-item-top"
  >
    <CarouselSecondGradient>
      <CarouselSecondInfo>
        <CarouselSecondEventName>{name}</CarouselSecondEventName>
        <CarouselSecondEventDate>{formattedDate}</CarouselSecondEventDate>
      </CarouselSecondInfo>
    </CarouselSecondGradient>
  </CarouselSecondTop>
);

CarouselSecondItemTop.propTypes = {
  backgroundImage: PropTypes.string,
  name: PropTypes.string,
  formattedDate: PropTypes.string,
};
