import styled from 'styled-components';

export const CarouselSecondLink = styled.div`
  height: 100%;

  a {
    height: 100%;
    display: flex;
    flex-direction: column;
    text-decoration: none;
  }
`;
