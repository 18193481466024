import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { RelatedEventsBlock } from '../../innerComponents';
import { Container } from '../../styles';
import { useSelector } from 'react-redux';

export const RowDisplayContent = (props) => {
  const { events = [] } = props;

  const { tenantId } = useSelector(({ pageConfig }) => pageConfig);

  const themeConfig = useContext(ThemeContext);

  const {
    contentGrid: { xs, sm },
  } = themeConfig.module.dStyles.elements.block;

  return (
    <Container data-testid="related-events-rows">
      <div className="container">
        <ul className="row">
          {events.map((event, index) => (
            <li key={index} className={`col-xs-${xs} col-sm-${sm}`}>
              <RelatedEventsBlock event={event} tenantId={tenantId} />
            </li>
          ))}
        </ul>
      </div>
    </Container>
  );
};

RowDisplayContent.propTypes = {
  events: PropTypes.array,
};
