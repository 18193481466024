import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const BlockWrapper = styled.div`
  display: block;
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements.block)}
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements.block)}
  
  &:hover {
    &:after {
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.vStyles.elements.block?.hoverAfter,
        )}
    }
  }

  a {
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.vStyles.elements.callToAction,
      )}
    ${({ theme }) =>
      renderStyledElementStyles(
        theme,
        theme.module.dStyles.elements.callToAction,
      )}
  }
`;
