import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const CarouselSecondGradient = styled.div`
  display: flex;
  align-items: flex-end;
  height: 100%;
  background: linear-gradient(181.25deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  z-index: 1;

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.dStyles.elements.carouselGradient,
    )}
`;
