import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from '../../../modules/react-slick';

const initialSettings = {
  dots: true,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  focusOnSelect: false,
};

export const Carousel = ({ children, embedSettings }) => {
  const ref = useRef(null);

  const handleResize = () => {
    const slider = ref.current.innerSlider;
    const { slideCount = 1, currentSlide = 0 } = slider.state;
    const { slidesToShow = initialSettings.slidesToShow } = slider.props;

    if (slideCount - currentSlide < slidesToShow) {
      slider.slickGoTo(slideCount - slidesToShow);
    }
  };

  const setRef = useCallback((node) => {
    ref.current = node;
    node?.slickGoTo(embedSettings?.currentSlide);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Slider {...initialSettings} {...embedSettings} ref={setRef}>
      {children}
    </Slider>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  embedSettings: PropTypes.object,
};

export default Carousel;
