import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import { SiteBuilderModule } from '../sharedComponents';
import {
  CAROUSEL,
  RELATED_STYLE_ONE,
  RELATED_STYLE_TWO,
  ROWS,
} from './constants';
import {
  CarouselFirstDisplayContent,
  CarouselSecondDisplayContent,
  RowDisplayContent,
} from './displayStyles';
import { ModuleWrapper, Title } from './styles';

const RelatedEventsModule = (props) => {
  const {
    section: {
      title,
      titleSize = 'h2',
      layout,
      layoutStyle,
      relatedEvents: events = [],
    } = {},
  } = props;

  const themeConfig = useContext(ThemeContext);

  const { relatedEventsModule } = themeConfig.siteBuilderModules;
  const hasLStyle = layout === CAROUSEL;

  const vStyles = hasLStyle
    ? relatedEventsModule.visualStyles[layout][layoutStyle]
    : relatedEventsModule.visualStyles[layout];
  const dStyles = hasLStyle
    ? relatedEventsModule.displayStyles[layout][layoutStyle]
    : relatedEventsModule.displayStyles[layout];

  const content = {
    get [ROWS]() {
      return <RowDisplayContent events={events} />;
    },
    get [CAROUSEL]() {
      const carouselContent = {
        [RELATED_STYLE_ONE]: <CarouselFirstDisplayContent events={events} />,
        [RELATED_STYLE_TWO]: <CarouselSecondDisplayContent events={events} />,
      };
      return carouselContent[layoutStyle];
    },
  };

  return (
    <SiteBuilderModule
      section={props.section}
      moduleTheme={{ dStyles, vStyles }}
    >
      <ModuleWrapper data-testid="related-events-module">
        {title && <Title as={titleSize.toLowerCase()}>{title}</Title>}
        {content[layout]}
      </ModuleWrapper>
    </SiteBuilderModule>
  );
};

RelatedEventsModule.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string,
    layout: PropTypes.oneOf(['ROWS', 'CAROUSEL']),
    layoutStyle: PropTypes.oneOf(['RELATED_STYLE_ONE', 'RELATED_STYLE_TWO']),
    relatedEvents: PropTypes.array,
  }).isRequired,
};

export default RelatedEventsModule;
