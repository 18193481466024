import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const Date = styled.div`
  position: absolute;
  bottom: 15px;
  left: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  z-index: 1;

  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements.date)}
`;
