import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const CarouselSecondEventName = styled.h4`
  max-width: 80%;
  margin-bottom: 8px;
  color: #fff;

  ${({ theme }) =>
    renderStyledElementStyles(
      theme,
      theme.module.vStyles.elements.carouselEventName,
    )}
`;
