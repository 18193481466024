import styled from 'styled-components';
import { renderStyledElementStyles } from '../../../../helpers';

export const CarouselSecondTop = styled.div`
  position: relative;
  background-size: cover;
  background-position: center;

  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.vStyles.elements.carouselTop)}
  ${({ theme }) =>
    renderStyledElementStyles(theme, theme.module.dStyles.elements.carouselTop)}

  &:hover {
    &:after {
      ${({ theme }) =>
        renderStyledElementStyles(
          theme,
          theme.module.vStyles.elements.carouselTop?.hoverAfter,
        )}
    }
  }
`;
